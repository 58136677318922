import {
  ColumnFilterParam,
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableSort,
  buildGroupsMap,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { STRINGS } from './suppliers-v2.strings';
import { SortOrder } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  Filter,
  FilterTypes,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import {
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3Operators,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { COLUMNS_GROUPS } from 'src/app/shared/models/datatable/v2/columns-groups.model';
import { FilterTypeOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';

export const defaultSort: DatatableSort = {
  [STRINGS.columns.suppliers.IGNORED.key]: {
    field: STRINGS.columns.suppliers.IGNORED.filterKey,
    order: SortOrder.ASC,
  },
  [STRINGS.columns.suppliers.NAME.key]: {
    field: STRINGS.columns.suppliers.NAME.filterKey,
    order: SortOrder.ASC,
  },
};

export const COLUMNS_ORDER: ColumnOrder = {
  [STRINGS.columns.suppliers.SELECTION.key]: 1,
  [STRINGS.columns.suppliers.ID.key]: 2,
  [STRINGS.columns.suppliers.TYPE.key]: 3,
  [STRINGS.columns.suppliers.NOTES.key]: 4,
  [STRINGS.columns.suppliers.NAME.key]: 5,
  [STRINGS.columns.suppliers.ATTRIBUTES.key]: 6,
  [STRINGS.columns.suppliers.MINIMUM_ORDER_VALUE.key]: 7,
  [STRINGS.columns.suppliers.WEIGHT.key]: 8,
  [STRINGS.columns.suppliers.VOLUME.key]: 9,
  [STRINGS.columns.suppliers.FIXED_COSTS.key]: 10,
  [STRINGS.columns.suppliers.LEAD_TIME.key]: 11,
  [STRINGS.columns.suppliers.LEAD_TIME_MEAN_ACTUAL.key]: 12,
  [STRINGS.columns.suppliers.LEAD_TIME_SD_PCT.key]: 13,
  [STRINGS.columns.suppliers.REPLENISHMENT_PERIOD.key]: 14,
  [STRINGS.columns.suppliers.USER_REPLENISHMENT_PERIOD.key]: 15,
  [STRINGS.columns.suppliers.CUTOFF.key]: 16,
  [STRINGS.columns.suppliers.REACTING_TO_BACKORDERS.key]: 17,
  [STRINGS.columns.suppliers.REACTING_TO_LOST_SALES.key]: 18,
  [STRINGS.columns.suppliers.AGENDA_MANAGED_MANUALLY.key]: 19,
  [STRINGS.columns.suppliers.ACTIONS.key]: 20,
};

export const defaultColumnsV2: DatatableColumnV2Groups<DatatableColumnV2> = {
  [COLUMNS_GROUPS.FIXED_START.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_START.key,
    groupName: COLUMNS_GROUPS.FIXED_START.name,
    columns: {
      [STRINGS.columns.suppliers.SELECTION.key]: {
        name: STRINGS.columns.suppliers.SELECTION.key,
        displayName: STRINGS.columns.suppliers.SELECTION.name,
        checked: true,
        type: null,
        selectable: false,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.SUPPLIER.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER.key,
    groupName: COLUMNS_GROUPS.SUPPLIER.name,
    columns: {
      [STRINGS.columns.suppliers.ID.key]: {
        name: STRINGS.columns.suppliers.ID.key,
        displayName: STRINGS.columns.suppliers.ID.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.TYPE.key]: {
        name: STRINGS.columns.suppliers.TYPE.key,
        displayName: STRINGS.columns.suppliers.TYPE.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.NOTES.key]: {
        name: STRINGS.columns.suppliers.NOTES.key,
        displayName: STRINGS.columns.suppliers.NOTES.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.NAME.key]: {
        name: STRINGS.columns.suppliers.NAME.key,
        displayName: STRINGS.columns.suppliers.NAME.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.ATTRIBUTES.key]: {
        name: STRINGS.columns.suppliers.ATTRIBUTES.key,
        displayName: STRINGS.columns.suppliers.ATTRIBUTES.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.MINIMUM_ORDER_VALUE.key]: {
        name: STRINGS.columns.suppliers.MINIMUM_ORDER_VALUE.key,
        displayName: STRINGS.columns.suppliers.MINIMUM_ORDER_VALUE.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.WEIGHT.key]: {
        name: STRINGS.columns.suppliers.WEIGHT.key,
        displayName: STRINGS.columns.suppliers.WEIGHT.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.VOLUME.key]: {
        name: STRINGS.columns.suppliers.VOLUME.key,
        displayName: STRINGS.columns.suppliers.VOLUME.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.FIXED_COSTS.key]: {
        name: STRINGS.columns.suppliers.FIXED_COSTS.key,
        displayName: STRINGS.columns.suppliers.FIXED_COSTS.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.LEAD_TIME.key]: {
        name: STRINGS.columns.suppliers.LEAD_TIME.key,
        displayName: STRINGS.columns.suppliers.LEAD_TIME.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.LEAD_TIME_MEAN_ACTUAL.key]: {
        name: STRINGS.columns.suppliers.LEAD_TIME_MEAN_ACTUAL.key,
        displayName: STRINGS.columns.suppliers.LEAD_TIME_MEAN_ACTUAL.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.LEAD_TIME_SD_PCT.key]: {
        name: STRINGS.columns.suppliers.LEAD_TIME_SD_PCT.key,
        displayName: STRINGS.columns.suppliers.LEAD_TIME_SD_PCT.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.REPLENISHMENT_PERIOD.key]: {
        name: STRINGS.columns.suppliers.REPLENISHMENT_PERIOD.key,
        displayName: STRINGS.columns.suppliers.REPLENISHMENT_PERIOD.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.USER_REPLENISHMENT_PERIOD.key]: {
        name: STRINGS.columns.suppliers.USER_REPLENISHMENT_PERIOD.key,
        displayName: STRINGS.columns.suppliers.USER_REPLENISHMENT_PERIOD.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.CUTOFF.key]: {
        name: STRINGS.columns.suppliers.CUTOFF.key,
        displayName: STRINGS.columns.suppliers.CUTOFF.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.REACTING_TO_BACKORDERS.key]: {
        name: STRINGS.columns.suppliers.REACTING_TO_BACKORDERS.key,
        displayName: STRINGS.columns.suppliers.REACTING_TO_BACKORDERS.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.REACTING_TO_LOST_SALES.key]: {
        name: STRINGS.columns.suppliers.REACTING_TO_LOST_SALES.key,
        displayName: STRINGS.columns.suppliers.REACTING_TO_LOST_SALES.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.suppliers.AGENDA_MANAGED_MANUALLY.key]: {
        name: STRINGS.columns.suppliers.AGENDA_MANAGED_MANUALLY.key,
        displayName: STRINGS.columns.suppliers.AGENDA_MANAGED_MANUALLY.name,
        checked: true,
        type: FilterTypes.FILTER_LIST,
        selectable: true,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.FIXED_END.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_END.key,
    groupName: COLUMNS_GROUPS.FIXED_END.name,
    columns: {
      [STRINGS.columns.suppliers.ACTIONS.key]: {
        name: STRINGS.columns.suppliers.ACTIONS.key,
        displayName: STRINGS.columns.suppliers.ACTIONS.name,
        checked: true,
        type: null,
        selectable: false,
        disabled: false,
      },
    },
  },
};

export const columnsGroupsMap: Map<string, string> =
  buildGroupsMap(defaultColumnsV2);

export const defaultFiltersV2: DatatableColumnV2Groups<Filter> = {
  [COLUMNS_GROUPS.SUPPLIER.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER.key,
    groupName: COLUMNS_GROUPS.SUPPLIER.name,
    columns: {
      [STRINGS.columns.suppliers.ID.key]: {
        name: STRINGS.columns.suppliers.ID.name,
        key: STRINGS.columns.suppliers.ID.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.TYPE.key]: {
        name: STRINGS.columns.suppliers.TYPE.name,
        key: STRINGS.columns.suppliers.TYPE.filterKey,
        uiModel: FilterTypes.FILTER_LIST,
        options: [
          {
            key: $localize`Vendor`,
            value: 0,
          },
          {
            key: $localize`Producer`,
            value: 1,
          },
        ],
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.OR,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.NOTES.key]: {
        name: STRINGS.columns.suppliers.NOTES.name,
        key: STRINGS.columns.suppliers.NOTES.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        partialMatch: true,
      },
      [STRINGS.columns.suppliers.HAS_NOTES.key]: {
        name: STRINGS.columns.suppliers.HAS_NOTES.name,
        key: STRINGS.columns.suppliers.HAS_NOTES.filterKey,
        uiModel: FilterTypes.FILTER_NULLABLE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.NULLABLE,
          operator: FilterTypeOptionsV2.IS_NULL,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.NAME.key]: {
        name: STRINGS.columns.suppliers.NAME.name,
        key: STRINGS.columns.suppliers.NAME.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.IGNORED.key]: {
        name: STRINGS.columns.suppliers.IGNORED.name,
        key: STRINGS.columns.suppliers.IGNORED.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.MINIMUM_ORDER_VALUE.key]: {
        name: STRINGS.columns.suppliers.MINIMUM_ORDER_VALUE.name,
        key: STRINGS.columns.suppliers.MINIMUM_ORDER_VALUE.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 0.1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.FIXED_COSTS.key]: {
        name: STRINGS.columns.suppliers.FIXED_COSTS.name,
        key: STRINGS.columns.suppliers.FIXED_COSTS.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 0.1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        nullable: true,
      },

      [STRINGS.columns.suppliers.LEAD_TIME.key]: {
        name: STRINGS.columns.suppliers.LEAD_TIME.name,
        key: STRINGS.columns.suppliers.LEAD_TIME.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        nullable: true,
      },
      [STRINGS.columns.suppliers.LEAD_TIME_MEAN_ACTUAL.key]: {
        name: STRINGS.columns.suppliers.LEAD_TIME_MEAN_ACTUAL.name,
        key: STRINGS.columns.suppliers.LEAD_TIME_MEAN_ACTUAL.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 0.1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.LEAD_TIME_SD_PCT.key]: {
        name: STRINGS.columns.suppliers.LEAD_TIME_SD_PCT.name,
        key: STRINGS.columns.suppliers.LEAD_TIME_SD_PCT.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 0.1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.REPLENISHMENT_PERIOD.key]: {
        name: STRINGS.columns.suppliers.REPLENISHMENT_PERIOD.name,
        key: STRINGS.columns.suppliers.REPLENISHMENT_PERIOD.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.USER_REPLENISHMENT_PERIOD.key]: {
        name: STRINGS.columns.suppliers.USER_REPLENISHMENT_PERIOD.name,
        key: STRINGS.columns.suppliers.USER_REPLENISHMENT_PERIOD.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        nullable: true,
      },
      [STRINGS.columns.suppliers.CUTOFF.key]: {
        name: STRINGS.columns.suppliers.CUTOFF.name,
        key: STRINGS.columns.suppliers.CUTOFF.filterKey,
        uiModel: FilterTypes.FILTER_TIME,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.TIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.REACTING_TO_BACKORDERS.key]: {
        name: STRINGS.columns.suppliers.REACTING_TO_BACKORDERS.name,
        key: STRINGS.columns.suppliers.REACTING_TO_BACKORDERS.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.REACTING_TO_LOST_SALES.key]: {
        name: STRINGS.columns.suppliers.REACTING_TO_LOST_SALES.name,
        key: STRINGS.columns.suppliers.REACTING_TO_LOST_SALES.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.AGENDA_MANAGED_MANUALLY.key]: {
        name: STRINGS.columns.suppliers.AGENDA_MANAGED_MANUALLY.name,
        key: STRINGS.columns.suppliers.AGENDA_MANAGED_MANUALLY.filterKey,
        uiModel: FilterTypes.FILTER_BOOLEAN,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.BOOLEAN,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.VOLUME.key]: {
        name: STRINGS.columns.suppliers.VOLUME.name,
        key: STRINGS.columns.suppliers.VOLUME.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 0.1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
      [STRINGS.columns.suppliers.WEIGHT.key]: {
        name: STRINGS.columns.suppliers.WEIGHT.name,
        key: STRINGS.columns.suppliers.WEIGHT.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 0.1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
      },
    },
  },
};

export const filtersGroupsMap: Map<string, string> =
  buildGroupsMap(defaultFiltersV2);

export const defaultPagination: DatatablePagination = {
  page: 0,
  size: 50,
  pageSizeOptions: [10, 15, 25, 50, 75, 100, 125, 150, 200],
};

export const ignoredFilter: ColumnFilterParam = {
  columnKey: 'IGNORED',
  optionSelected: null,
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: {
    to: false,
  },
  applyAll: false,
};

export const leadTimeFilterNullOr: ColumnFilterParam = {
  columnKey: 'LEAD_TIME',
  optionSelected: FilterTypes.FILTER_IS_NULL,
  subOperator: ServiceRequestInfoV3Operators.OR,
  values: { to: null },
  applyAll: false,
};

export const leadTimeFilterZeroOr: ColumnFilterParam = {
  columnKey: 'LEAD_TIME',
  optionSelected: FilterTypes.FILTER_NUMBER,
  subOperator: ServiceRequestInfoV3Operators.OR,
  values: { to: 0 },
  applyAll: false,
  extra: true,
};

export const fixedCostsFilter: ColumnFilterParam = {
  columnKey: 'FIXED_COSTS',
  optionSelected: FilterTypes.FILTER_IS_NULL,
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: { to: null },
  applyAll: false,
};

export const userReplenishmentPeriodNullFilterOr: ColumnFilterParam = {
  columnKey: 'USER_REPLENISHMENT_PERIOD',
  optionSelected: FilterTypes.FILTER_IS_NULL,
  subOperator: ServiceRequestInfoV3Operators.OR,
  values: { to: null },
  applyAll: false,
};

export const userReplenishmentPeriodZeroFilterOr: ColumnFilterParam = {
  columnKey: 'USER_REPLENISHMENT_PERIOD',
  optionSelected: FilterTypes.FILTER_NUMBER,
  subOperator: ServiceRequestInfoV3Operators.OR,
  values: { to: 0 },
  applyAll: false,
  extra: true,
};

export const SUPPLIERS_FILTERS = {
  FILL_LEAD_TIME: [ignoredFilter, leadTimeFilterNullOr, leadTimeFilterZeroOr],
  FILL_REPLENISHMENT_PERIOD: [
    ignoredFilter,
    userReplenishmentPeriodNullFilterOr,
    userReplenishmentPeriodZeroFilterOr,
  ],
  FILL_FIXED_COSTS: [ignoredFilter, fixedCostsFilter],
};
