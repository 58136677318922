import { ServiceRequestInfo } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import { SuppliersV2StateModel } from '../state/suppliers-v2.state';
import {
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
} from './suppliers-v2-data-table.model';
import { TableSelection } from 'src/app/shared/models/selection/selection.model';
import {
  Supplier,
  SupplierCommunication,
  SupplierCommunications,
  SupplierManager,
} from 'src/app/shared/models/suppliers/v2/supplier-v2.model';
import { defaultDatatableStateModel } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';

export interface SelectedSupplier {
  uuid: string;
  ignored: boolean;
}

export interface EnableDisableSupplierProperties {
  uuid: string;
  ignored: boolean;
}

export interface ExportMetadata {
  visibleColumns: string[];
  sortBy: string;
  order: string;
  filter: string;
  source: string;
  include: string[];
}
export interface SupplierUpdateProperties {
  name?: string;
  ignored?: boolean;
  fixedCosts?: number;
  exportQuantityAsLots?: boolean;
  excludePriceFromExports?: boolean;
  backorderThreshold?: number;
  backordersReaction?: number;
  lostSalesReaction?: number;
  backorders?: boolean;
  reactingToLostSales?: boolean;
  lostSalesMovReaction?: number;
  agendaManagedManually?: boolean;
  agendaManagedData?: string;
  deliveryTime?: number;
  minimumOrderValue?: number;
  replenishmentPeriodUserSpecified?: number;
  resetEmails?: boolean;
  emails?: string[];
  maxLoadCapacity?: number;
  containerVolume?: number;
  preferredEmailLanguage?: string;
  globalLocationNumber?: string;
  planningDate?: string;
  cutoffHH?: string;
  cutoffMM?: string;
  supplierManager?: SupplierManager;
  notes?: string;
  communicationMethod?: SupplierCommunication;
  type?: string;
  lastUpdatedSupplierType?: string;
}

export interface SupplierUpdateMultiple {
  suppliers: SupplierUpdateMultipleProperties[];
}
export interface SupplierUpdateMultipleProperties {
  uuid: string;
  ignored?: boolean;
  fixedCosts?: number;
  exportQuantityAsLots?: boolean;
  excludePriceFromExports?: boolean;
  backorderThreshold?: number;
  backordersReaction?: number;
  lostSalesReaction?: number;
  backorders?: boolean;
  reactingToLostSales?: boolean;
  lostSalesMovReaction?: number;
  agendaManagedManually?: boolean;
  agendaManagedData?: string;
  deliveryTime?: number;
  minimumOrderValue?: number;
  replenishmentPeriodUserSpecified?: number;
  resetEmails?: boolean;
  emails?: string[];
  maxLoadCapacity?: number;
  containerVolume?: number;
  resetMaxLoadCapacity?: boolean;
  resetContainerVolume?: boolean;
  notes?: string;
}

export interface ExportSuppliersBody {
  fileType: string;
  fileName: string;
  visibleColumns: string[];
  filter?: string;
  sortBy?: string;
  order?: string;
  include?: string[];
}

export interface SuppliersV2ResetModel {
  selection: TableSelection<SelectedSupplier>;
  suppliers: Supplier[];
  currentSupplier: Supplier;
  loading: boolean;
}

export const defaultState: SuppliersV2StateModel = {
  ...defaultDatatableStateModel,
  selection: {},
  pagination: defaultPagination,
  columnsGroups: defaultColumnsV2,
  filtersGroups: defaultFiltersV2,
  extraFiltersGroups: defaultFiltersV2,
  sortBy: defaultSort,
  search: [],
  suppliers: [],
  currentSupplier: null,
  page: { totalElements: 0 },
  filterOpened: false,
  loading: false,
};

export const defaultResetState: SuppliersV2ResetModel = {
  selection: {},
  suppliers: [],
  currentSupplier: null,
  loading: false,
};

export interface LoadSuppliersRequestInfo extends ServiceRequestInfo {
  webshopId: number;
}

export interface SuppliersRequestInfo {
  webshopId: number;
  previousPage: number;
  page: number;
  limit: number;
  sortBy: string;
  order: string;
  filters: string;
}
